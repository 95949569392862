<template>
  <div class="kw-tabs-wrapper-content">

    <div class="all-infos">
      <h3>{{ $t("POLICIES.POLICY_INFORMATION_GENERAL") }}</h3>

      <div class="all-infos-text">
        <dl>
          <dt>{{ $t("POLICIES.POLICY_NAME") }}</dt>
          <dd>
            {{ policy.name }}
          </dd>
        </dl>

        <dl>
          <dt>{{ $t("POLICIES.STATUS") }}</dt>
          <dd>
            {{ policy.status ? $t("POLICIES.POLICY_ACTIVE") : $t("POLICIES.POLICY_INACTIVE") }}
          </dd>
        </dl>

        <dl>
          <dt>{{ $t("POLICIES.POLICY_TYPE_DETAILS") }}</dt>
          <dd>
            {{ $t(`POLICIES.POLICY_${policy.policies_type}`) }}
          </dd>
        </dl>

        <dl>
          <dt>{{ $t("POLICIES.POLICY_DESCRIPTION") }}</dt>
          <dd>
            <div v-html="policy.description"></div>
          </dd>
        </dl>
      </div>

      <h3 v-if="isPaymentType">{{ $t("POLICIES.POLICY_PAYMENTS") }}</h3>
      <div class="all-infos-text" v-if="isPaymentType">
        <dl>
          <dt>{{ $t("POLICIES.PAYMENT_TYPE") }}</dt>
          <dd>
            <div>{{ $t(`POLICIES.POLICY_PAYMENT_TYPE_${policy.payment_policies}`) }}</div>
          </dd>
        </dl>
        <dl>
          <dt>{{ $t("POLICIES.POLICY_HOURS") }}</dt>
          <dd>
            <div>{{ policy.payment_policies_hours + ' ' + $t(`POLICIES.HOURS`) }}</div>
          </dd>
        </dl>
      </div>

      <h3 v-if="isPaymentType">{{ $t("POLICIES.PAYMENT") }}</h3>
      <div class="all-infos-text" v-if="isPaymentType">
        <dl>
          <dt>{{ $t("POLICIES.PAYMENT_TYPE") }}</dt>
          <dd>
            <div>{{ $t(`POLICIES.PAYMENT_TYPE_${policy.payment_type}_TITLE`) }}</div>
          </dd>
        </dl>
        <dl>
          <dt>{{ $t(`POLICIES.PAYMENT_TYPE_${policy.payment_type}_TITLE`) }}</dt>
          <dd>
            <div>{{ policy.payment_value }}</div>
          </dd>
        </dl>
      </div>

      <h3 v-if="isCancellationType">{{ $t("POLICIES.POLICY_CANCELLATION") }}</h3>
      <div class="all-infos-text" v-if="isCancellationType">
        <dl>
          <dt>{{ $t("POLICIES.PAYMENT_TYPE") }}</dt>
          <dd>
            <div>{{ $t(`POLICIES.POLICY_PAYMENT_TYPE_${policy.payment_policies}`) }}</div>
          </dd>
        </dl>
        <dl>
          <dt>{{ $t("POLICIES.POLICY_HOURS") }}</dt>
          <dd>
            <div>{{ policy.payment_policies_hours + ' ' + $t(`POLICIES.HOURS`) }}</div>
          </dd>
        </dl>
      </div>

      <h3 v-if="isCancellationType">{{ $t("POLICIES.CANCELLATION_TITLE") }}</h3>
      <div class="all-infos-text" v-if="isCancellationType">
        <dl>
          <dt>{{ $t("POLICIES.PAYMENT_TYPE") }}</dt>
          <dd>
            <div>{{ $t(`POLICIES.PAYMENT_TYPE_${policy.payment_type}_TITLE`) }}</div>
          </dd>
        </dl>
        <dl>
          <dt>{{ $t(`POLICIES.PAYMENT_TYPE_${policy.payment_type}_TITLE`) }}</dt>
          <dd>
            <div>{{ policy.payment_value }}</div>
          </dd>
        </dl>
      </div>

      <h3 v-if="isGuaranteeType">{{ $t("POLICIES.POLICY_GUARANTEE") }}</h3>
      <div class="all-infos-text" v-if="isGuaranteeType">

      </div>

      <h3>{{ $t("POLICIES.COMMUNICATION") }}</h3>
      <div class="all-infos-text">
        <dl>
          <dt>{{ $t("POLICIES.SUCCESS") }}</dt>
          <dd>
            <div>{{ policy.alert_success ? $t(`POLICIES.SEND_EMAIL`) : $t(`POLICIES.NO_SEND_EMAIL`) }}</div>
          </dd>
        </dl>
        <dl>
          <dt>{{ $t("POLICIES.ERROR") }}</dt>
          <dd>
            <div>{{ policy.alert_success ? $t(`POLICIES.SEND_EMAIL`) : $t(`POLICIES.NO_SEND_EMAIL`) }}</div>
          </dd>
        </dl>
        <dl>
          <dt>{{ $t("POLICIES.ERROR") }}</dt>
          <dd>
            <div>{{ policy.alert_success ? $t(`POLICIES.SEND_EMAIL_CAMPING`) : $t(`POLICIES.NO_SEND_EMAIL_CAMPING`) }}</div>
          </dd>
        </dl>
      </div>
    </div>

  </div>
</template>

<script>
import {
  POLICIES_TYPE_CANCELLATION,
  POLICIES_TYPE_GUARANTEE,
  POLICIES_TYPE_PAYMENTS
} from "@/constants/common";

export default {
  name: "policy-view-global",

  props: ["policy"],

  components: {  },

  data() {
    return {};
  },

  computed: {
    isPaymentType() {
      return this.policy?.policies_type === POLICIES_TYPE_PAYMENTS;
    },
    isCancellationType() {
      return this.policy?.policies_type === POLICIES_TYPE_CANCELLATION;
    },
    isGuaranteeType() {
      return this.policy?.policies_type === POLICIES_TYPE_GUARANTEE;
    },
  },

  created() {},

  methods: {
    // spotUpdated() {
    //   this.$emit("spotUpdated", true);
    // },
  },

  mounted() {},

  watch: {
    policy(policy) {},
  },
};
</script>
