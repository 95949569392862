<template>
  <div class="container-fluid">
    <policy-form
      :loading="loading"
      :policyData="policy"
      :formErrors="formErrors"
      @policySubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
      @onClosePolicyModal="onClosePolicyModal"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import { mapGetters } from "vuex";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultPolicy from "../defaultPolicy";
import PolicyForm from "../partials/PolicyForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    PolicyForm,
  },

  mixins: [alertLeave],

  computed: {
    ...mapGetters("organizations", {
      globalOrganization: "globalOrganization",
    }),
  },

  data() {
    let policyData = cloneDeep(defaultPolicy);
    policyData.id = -2;
    policyData = this.$fillUserOrganizationData(policyData);

    return {
      policy: policyData,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.policy.organization = this.globalOrganization;
  },

  methods: {
    async handleSubmit(policy) {
      this.loading = true;

      const policyData = cloneDeep(policy);
      delete policyData.id;

      try {
        await this.$store.dispatch("policies/add", policyData);
        this.$notify({
          type: "success",
          message: this.$t("POLICIES.POLICY_ADDED"),
        });
        const policy = await this.$store.getters["policies/policy"];
        this.$emit("onViewPolicy", policy, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },

    onClosePolicyModal() {
      this.$emit("onClosePolicyModal");
    },
  },
};
</script>
